.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: 'Roboto', Arial, sans-serif;
}

.App-main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background-color: #F5F5F5;
}

h1 {
  color: #0A2342;
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

p {
  color: #333333;
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.button-container {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
}

.platform-button {
  background-color: #0A2342;
  color: white;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s, transform 0.3s;
}

.platform-button:hover {
  background-color: #153e6f;
  transform: translateY(-2px);
}

@media (max-width: 600px) {
  h1 {
    font-size: 2rem;
  }

  p {
    font-size: 1rem;
  }

  .platform-button {
    width: 100%;
  }
}