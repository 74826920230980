.Header {
  background: linear-gradient(45deg, #0A2342 30%, #153e6f 90%);
  color: white;
  padding: 1rem;
  box-shadow: 0 3px 5px 2px rgba(0, 0, 0, .3);
}

.Header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}

.Header-left {
  display: flex;
  align-items: left;
}

.Header-right {
  display: flex;
  align-items: right;
}

.Header-logo {
  height: 40px;
  padding-left: 5%;
}

.Login-link {
  color: white;
  text-decoration: none;
  font-size: 1.1rem;
}

.Header-right .MuiIconButton-root {
  font-size: 2rem;
}

.Header-right .user-menu-button {
  margin-right: 15%;
}

@media (max-width: 600px) {
  .Header-logo {
    height: 30px;
  }
}